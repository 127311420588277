import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import Factura from '../components/Factura'
import GoogleMapReact from 'google-map-react'
import Marcador from '../components/Marcador'
import NumberFormat from 'react-number-format'
import Estados from '../components/Estados'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Motoristas from '../components/Motoristas'

import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import Moment from 'react-moment'
import 'moment/locale/es'

import Modal from 'react-modal'

import {
  selectSucursales,
  selectUser
} from '../features/masterSlice'

import { useSelector } from 'react-redux'
import AutoDriveIn from '../components/AutoDriveIn'
import LogEstados from "../components/LogEstados"
import FleetDetail from "../components/FleetDetail"
import PedibossJob from "../components/PedibossJob"

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
}


const modalidadNoDelivery = ['drivein', 'totem']



export default function PedidoPage (props) {

  const {refreshHandler} = props;
  const location = useLocation()
  const [verMapa, setVerMapa] = useState(false)
  const [pedidoId, setPedidoId] = useState()
  const [pedido, setPedido] = useState()
  const [map, setMap] = useState()
  const [maps, setMaps] = useState()
  const [loading, setLoading] = useState(false)
  const [coordinates, setCoordinates] = useState({ lat: 1, lng: 1 })
  const [modoContenedor, setModoContenedor] = useState('')
  const sucursales = useSelector(selectSucursales)
  const storage = process.env.REACT_APP_STORAGE
  const user = useSelector(selectUser)

  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    console.log('props', props)
    if (props.pedidoId) {
      setModoContenedor('w-full')
      setPedidoId(props.pedidoId)
    } else {
      setModoContenedor('w-full')   //setModoContenedor('container');
      setPedidoId(props.match.params.id)
    }

  }, [])

  useEffect(() => {



    if (pedido && pedido.direccion?.ubicacion){

          setCoordinates({
            lat: pedido.direccion.ubicacion.latitude,
            lng: pedido.direccion.ubicacion.longitude
          })
    }
    

    console.log("pedido dir", pedido?.direccion)
    
    if (pedido && pedido.direccion?.geometry){
          setCoordinates({
            lat: pedido?.direccion.geometry.location.lat,
            lng: pedido?.direccion.geometry.location.lng
          })
    }    
    

 
 
 
  }, [pedido])

  useEffect(() => {
    if (pedidoId) refreshData()
  }, [pedidoId])

  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
    setMap(map)
    setMaps(maps)

  }


  const reasignarPedido = (jobId) => {
      setLoading(true)
      axios.post(process.env.REACT_APP_API_ENDPOINT + `delivery/pedido/reasignarJobPb/${pedido._id}/${jobId}`).then(
        (result) => {
          refreshData();
        }
      ).catch(e => {
        alert('FAIL:' + e)
        setLoading(false)
      })
  }


  const integrar = () => {

    setLoading(true)
    axios.get(process.env.REACT_APP_API_ENDPOINT + `integracion/integrar?id=${pedido._id}`).then(
      (result) => {
        // alert( "OK: "+ result.data.message.registeredDate);
        props.closeModalHandler()
      }
    ).catch(e => {
      alert('FAIL:' + e)
      setLoading(false)
    })
  }


  const integrarPb = () => {

    setLoading(true)


    const pin = prompt("Ingrese el pin de integracion")

    if(pin){

      const data= {
        pedidoId: pedido._id,
        pin: pin
      }
      axios.post(process.env.REACT_APP_API_ENDPOINT + `delivery/pedido/integrarPb`, data).then(
        (result) => {
          console.log("result", result.data)

          const {status, message} = result.data

          if(status){
            refreshData();
          }else{
            alert(message)
          }

          // alert( "OK: "+ result.data.message.registeredDate);

          setLoading(false)
          // props.closeModalHandler()
        }
      ).catch(e => {
        console.log("fail integrarPb error", e)
        alert( "Falla de comunicación" );
        setLoading(false)
      }).finally(()=>{

      })
    }else{
      setLoading(false)
    }

  }




  const cambioSucursal = (e) => {
    // console.log(e.target.value)
    // console.log(pedido)
    // setPedido({
    //   ...pedido,
    //   sucursal: e.target.value
    // })
    let data = {
      estado: pedido.estado,
      id: pedido._id,
      sucursal: e.target.value
    }
    postChangeEstado(data)
  }

  const cambiarEstado = (estado) => {

    let data = {
      estado: estado,
      id: pedido._id
    }

    if (estado === 'Borrado') {

      data.ciclo = 'cerrado'

      confirmAlert({
        title: 'Confirmación',
        message: `Borrar orden ${pedido.ticket} ?`,
        buttons: [
          {
            label: 'Aceptar',
            onClick: () => postChangeEstado(data)
          },
          {
            label: 'Cancelar',
            onClick: () => {}
          }
        ]
      })

    } else if (estado === 'En camino') {
      setOpenModal(true)

    } else if (estado === 'En producción') {
      confirmAlert({
        title: 'Confirmación',
        message: `Desea forzar el cambio de estado de la orden ${pedido.ticket} sin automatizar a CINET ?\r\n(solo cuando se va a cargar manualmente el ticket)`,
        buttons: [
          {
            label: 'Aceptar',
            onClick: () => postChangeEstado(data)
          },
          {
            label: 'Cancelar',
            onClick: () => {}
          }
        ]
      })

    } else {
      postChangeEstado(data)
    }

  }

  const motoristaHandler = (motorista) => {

    let data = {
      estado: 'En camino',
      id: pedido._id,
      nombreMotorista: motorista.nombre,
      id_motorista: motorista._id
    }

    postChangeEstado(data)

  }
  const driveInHandler = () => {
    let data = {
      estado: 'En camino',
      id: pedido._id,
      nombreMotorista: 'DI',
      id_motorista: '0'
    }
    // closeModalHandler(false)
    console.log('esta')
    postChangeEstado(data)
  }

  const postChangeEstado = (data) => {
    setLoading(true)
    axios.post(process.env.REACT_APP_API_ENDPOINT + `delivery/pedido/cambioEstado`, data).then(
      (result) => {
        // alert( "OK: "+ result.data.message.registeredDate);
        setLoading(false)
        refreshHandler()
        props.closeModalHandler()
      }
    ).catch(e => {
      //alert( "FAIL:"+ e);
      setLoading(false)
    })
  }

  const refreshData = () => {

    setPedido(null)
    setLoading(true)

    console.log('call pedido data', pedidoId)

    axios.get(`delivery/pedido/${pedidoId}`).then(
      (result) => {
        setPedido(result.data)
        setLoading(false)

      }
    ).catch(error => {
      console.log('error ', error)
      setLoading(false)

    })

  }

  const closeModalHandler = () => {
    setOpenModal(false)
  }
    let url = 'https://www.google.com/maps?q=' + coordinates.lat + ',' + coordinates.lng



  if(loading)return <div className="w-full     flex  justify-center   ">
      <div className="flex justify-center items-center"> <p>cargando...</p>
      </div>
  </div>

  return (
    <div className="w-full     flex  justify-center   ">


      {pedido && <div className={`h-full p-1 md:p-5   ${modoContenedor}`}>


        <Modal
          ariaHideApp={false}
          isOpen={openModal}
          style={customStyles}
          contentLabel="Pedido"
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => setOpenModal(false)}
        >

          <div className=" flex   h-full justify-between  w-full">
            
            {( !modalidadNoDelivery.includes(pedido.modalidad)    ) &&     // pedido.modalidad !== 'drivein'
            <Motoristas data={pedido.motoristas} closeModalHandler={closeModalHandler} sucursal={pedido.sucursal}
                        motoristaHandler={motoristaHandler}/>
            }
            {(   modalidadNoDelivery.includes(pedido.modalidad)  ) &&    //pedido.modalidad === 'drivein'
            <AutoDriveIn
              // closeModalHandler={closeModalHandler}
              pedido={pedido}
              driveInHandler={driveInHandler}/>
            }

          </div>


        </Modal>


        <div className="flex-none sm:flex-1 md:flex items-start  ">


          <div className="flex flex-row  rounded shadow sm:w-full md:w-3/5  sm:mb-5  p-5 mr-5 bg-gray-300  w-full ">


            {user.rol === 'drivein' &&
            <div className="flex flex-col  justify-start   mr-5 ">
              <button className=" p-1 w-ful uppercase bg-gray-400 text-xl h-16 text-gray-900 font-semibold mb-1 rounded"
                      onClick={() => props.closeModalHandler()}>Cerrar
              </button>
              <button className=" p-1 w-ful uppercase bg-gray-400 text-xl h-16 text-gray-900 font-semibold mb-2 rounded"
                      onClick={() => refreshData()}>RECARGAR
              </button>
            </div>}


            {
              user.rol !== 'drivein' && props.pedidoId &&
              <div className="flex flex-col  justify-start gap-2  mr-5 hidden">
                <button className=" p-1 w-ful uppercase bg-gray-400 text-xs text-gray-900 font-semibold mb-1 rounded"
                        onClick={() => props.closeModalHandler()}>Cerrar
                </button>
                <button className=" p-1 w-ful uppercase bg-gray-400 text-xs text-gray-900 font-semibold mb-2 rounded"
                        onClick={() => refreshData()}>RECARGAR
                </button>
                <button className=" p-1 w-ful uppercase bg-red-500 text-xs text-white font-semibold mb-5 rounded"
                        onClick={() => cambiarEstado('Borrado')}> BORRAR
                </button>
                {pedido.estado === 'En espera' && (!loading ? <button
                  className=" p-1 w-ful uppercase bg-green-400 te5f514184dfdb862ec024ed22xt-xs text-gray-900 font-semibold mb-1 rounded"
                  onClick={() => integrar()}>A caja</button> : 'aguarde...')}
                {pedido.estado === 'En espera' && (!loading ? <div>
                  <div className="py-2"><select name="suc" defaultValue={pedido.sucursal} onChange={(e, pedido) => cambioSucursal(e, pedido)}>
                    {sucursales.map(i =>
                      <option value={i.nombre}>  {i.nombre} </option>
                    )}
                  </select></div>

                </div> : '')}


                {
                   !pedido.integracionPb  && <button
                  className=" p-1 w-ful uppercase bg-green-400 te5f514184dfdb862ec024ed22xt-xs text-gray-900 font-semibold mb-1 rounded"
                  onClick={() => integrarPb()}>INTEGRAR A PB</button>
                  }

                {pedido.estado === 'En producción' &&
                <button className=" p-1 w-ful uppercase bg-green-400 text-xs text-gray-900 font-semibold mb-1 rounded"
                        onClick={() => cambiarEstado('Listo para entregar')}><FontAwesomeIcon
                  icon={['fas', 'arrow-right']}/> Listo</button>}
                {pedido.estado === 'Listo para entregar' && pedido.modalidad !== 'fasttrack' &&
                <button className=" p-1 w-ful uppercase bg-green-400 text-xs text-gray-900 font-semibold mb-1 rounded"
                        onClick={() => cambiarEstado('En camino')}><FontAwesomeIcon icon={['fas', 'arrow-right']}/> En
                  camino</button>}
                {(pedido.estado === 'En camino' || (pedido.estado === 'Listo para entregar' && pedido.modalidad === 'fasttrack')) &&
                <button className=" p-1 w-ful uppercase bg-green-400 text-xs text-gray-900 font-semibold mb-1 rounded"
                        onClick={() => cambiarEstado('Entregado')}><FontAwesomeIcon icon={['fas', 'arrow-right']}/>Entregado
                </button>}
                {pedido.estado === 'En producción' && pedido.callbackData &&
                <button className=" p-1 w-ful uppercase bg-red-500 text-xs text-white font-semibold mb-1 rounded"
                        onClick={() => cambiarEstado('En espera')}><FontAwesomeIcon icon={['fas', 'arrow-left']}/> En
                  espera</button>}
                {pedido.estado === 'Listo para entregar' &&
                <button className=" p-1 w-ful uppercase bg-red-500 text-xs text-white font-semibold mb-1 rounded"
                        onClick={() => cambiarEstado('En producción')}><FontAwesomeIcon
                  icon={['fas', 'arrow-left']}/> En producción</button>}
                {(pedido.estado === 'En camino' || pedido.estado === 'Entregado' || pedido.estado === 'Borrado') &&
                <button className=" p-1 w-ful uppercase bg-red-500 text-xs text-white font-semibold mb-1 rounded mb-5"
                        onClick={() => cambiarEstado('Listo para entregar')}><FontAwesomeIcon
                  icon={['fas', 'arrow-left']}/> Listo para entregar</button>}
                {pedido.estado === 'En caja' && <button
                  className=" p-1 w-ful uppercase bg-red-500 text-xs text-white font-semibold mb-1 rounded self-end "
                  onClick={() => cambiarEstado('En producción')}><FontAwesomeIcon icon={['fas', 'arrow-right']}/> FORZAR
                  En producción</button>}
              </div>
            }


            <div className="flex flex-col w-full">


            <div className="block uppercase   text-center tracking-wide text-gray-400 text-xs font-bold mb-2">
              {pedido._id}
              </div>

              <div className="block uppercase   text-center tracking-wide text-gray-700 text-xs font-bold mb-2 text-sm">
                <Moment format="LLL" locale="es">{pedido.created_at}</Moment>
              </div>

              <div className="block uppercase   text-center tracking-wide text-gray-700 text-xs font-bold text-2xl">
                {pedido.modalidad} - {pedido.estado}
              </div>

              <div className="block uppercase   text-center tracking-wide text-gray-700 text-xs font-bold mb-2 text-sm">
                {pedido.sucursal}
              </div>

              {
                
                (   pedido.estado!=="Borrado" && user.rol ==='superadmin')  &&  <div>
                <button className=" p-1 w-ful uppercase bg-red-500 text-xs text-white font-semibold mb-5 rounded"
                          onClick={() => cambiarEstado('Borrado')}> BORRAR PEDIDO
                  </button>
              </div>}


              <label className="block uppercase   tracking-wide text-gray-500 text-xs font-bold ">CLIENTE:</label>
              <div className="block uppercase   tracking-wide text-gray-700 text-xs font-bold mb-2 text-2xl">
                {pedido.cliente?.nombre} {pedido.cliente?.apellido} ({pedido.cliente?.celular})
              </div>


              {pedido.modalidad !== 'fasttrack' && pedido.modalidad !== 'drivein' && pedido.modalidad !== 'totem' &&
              <div><label
                className="block uppercase   tracking-wide text-gray-500 text-xs font-bold ">DIRECCION:</label>
                <div className="block uppercase   tracking-wide text-gray-700 text-xs font-bold text-xl">
                  {pedido.direccion.calle} { JSON.stringify(pedido.direccion.formatted_address)}   
                  
                </div>
                <div className="block uppercase   tracking-wide text-gray-700 text-xs font-bold mb-2 text-xl">
                  {pedido.direccion.referencias}
                </div>
              </div>
              }


              {(pedido.modalidad === 'fasttrack' | pedido.modalidad === 'drivein' | pedido.modalidad === 'pickup') &&
              <div><label className="block uppercase   tracking-wide text-gray-500 text-xs font-bold ">VEHICULO:</label>
                <div className="block uppercase   tracking-wide text-gray-700 text-xs font-bold text-xl">
                  {pedido.vehiculo?.tipo} - {pedido.vehiculo?.color} - {(pedido.vehiculo?.patente) ? pedido.vehiculo?.patente : ''}
                </div>
              </div>
              }


              <label className="block uppercase   tracking-wide text-gray-500 text-xs font-bold ">ORDEN:</label>
              <div className="block uppercase   tracking-wide text-gray-700 text-xs font-bold mb-2 text-xl">
                {pedido.ticket}
              </div>


              {pedido.tomado &&
              <div><label className="block uppercase   tracking-wide text-gray-500 text-xs font-bold ">TOMADO EN CC
                POR:</label>
                <div className="block uppercase   tracking-wide text-gray-700 text-xs font-bold mb-2 text-xl">
                  {pedido.tomado.name}
                </div>
              </div>}


              {
                !modalidadNoDelivery.includes(pedido.modalidad) && <div>
                  <label className="block uppercase   tracking-wide text-gray-500 text-xs font-bold ">MOTORISTA:</label>
                  <div className="block uppercase   tracking-wide text-gray-700 text-xs font-bold mb-2 text-xl">
                    {pedido.nombreMotorista}
                  </div>
                </div>
              }







              {verMapa ?
                <div className="bg-gray-700 w-full h-64">
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyCjyZq8RSfrImlIfnf5Rhbq-IXRqA5Dh7s' }}
                    defaultCenter={coordinates}
                    defaultZoom={16}
                    center={coordinates}
                    yesIWantToUseGoogleMapApiInternals={false}
                    onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                    distanceToMouse={() => {}}
                    layerTypes={['TrafficLayer', 'TransitLayer']}

                  >
                    <Marcador
                      lat={coordinates.lat}
                      lng={coordinates.lng}
                      text="M"
                    />
                  </GoogleMapReact></div> :
                !modalidadNoDelivery.includes(pedido.modalidad) &&
                <button className="p-2 uppercase bg-gray-400 text-sm text-gray-900 font-semibold mr-5 rounded"
                        onClick={() => setVerMapa(true)}>Ver mapa</button>
              }
              <br/>

              {(pedido.modalidad === 'delivery' && pedido.direccion.exact && pedido.direccion.exact === 'false') &&
              <label style={{ 'display': 'flex', 'justify-content': 'center' }}
                     className=" p-1 w-ful uppercase bg-red-500 text-xs text-white font-semibold mb-5 rounded">Ubicacion
                inexacta</label>
              }
              {

                !modalidadNoDelivery.includes(pedido.modalidad) &&
                <div>
                  <a href={url} target="_blank">Link para motorista </a><br/>
                  <label>{url}</label>
                </div>
              }


            {
              pedido.pb_fleet_details && 
              <div className="mt-5">
              <FleetDetail data={pedido.pb_fleet_details}/>
            </div>
            }



            {
          
               user.rol ==='superadmin'  &&   <div className="mt-5">
                  <PedibossJob
                  pedido={pedido}
                  reasignarHandler = {reasignarPedido}
                  />
                </div>
            }

            </div>

          </div>
          {/* fin columna 1 */}


          <div className="  sm:w-full md:w-2/5  sm:mb-5 flex flex-col gap-5  ">
            <div className="rounded shadow p-5 bg-gray-300  ">
              <label className="block uppercase   tracking-wide text-gray-500 text-xs font-bold ">PEDIDO:</label>
              <Factura data={pedido}/>


              <label className="block uppercase   tracking-wide text-gray-500 text-xs font-bold ">TICKET CINET:</label>
              <div className="block uppercase   tracking-wide text-gray-700 text-xs font-bold mb-2 text-xl">
                {pedido.ticketCinet}
              </div>

              <label className="block uppercase   tracking-wide text-gray-500 text-xs font-bold ">FORMA DE PAGO:</label>
              <div className="block uppercase   tracking-wide text-gray-700 text-xs font-bold mb-2 text-xl">
                {pedido.formaPago}
              </div>


              {pedido.giftcard && <div>
                <label
                  className="block uppercase   tracking-wide text-gray-500 text-xs font-bold ">GIFTCARD: {pedido.giftcard}</label>
                <div className="block uppercase   tracking-wide text-gray-700 text-xs font-bold mb-2 text-xl">
                  <NumberFormat value={pedido.montoGiftcard} displayType={'text'} thousandSeparator={true}
                                prefix={'Gs. '}/>
                </div>

              </div>}


              <label className="block uppercase   tracking-wide text-gray-500 text-xs font-bold ">FACTURA:</label>
              <div className="block uppercase   tracking-wide text-gray-700 text-xs font-bold mb-2 text-2xl">
                {pedido.factura.nombre} - {pedido.factura.ruc}
              </div>


              {pedido.observaciones &&
              <div><label
                className="block uppercase   tracking-wide text-gray-500 text-xs font-bold ">OBSERVACIONES:</label>
                <div className="block uppercase   tracking-wide text-gray-700 text-xs font-bold mb-2 text-xl">
                  {pedido.observaciones}
                </div>
              </div>
              }
            </div>


            <div className="rounded shadow   p-5 bg-gray-300">
              <label className="block uppercase   tracking-wide text-gray-500 text-xs font-bold ">ESTADOS:</label>
              <Estados data={pedido.estados}/>
            </div>



            <div className="rounded shadow   p-5 bg-gray-300">
              <label className="block uppercase   tracking-wide text-gray-500 text-xs font-bold ">LOG:</label>
              <LogEstados data={pedido}/>
            </div>




          </div>
          {/* fin columna 2 */}


        </div>


      </div>}

    </div>
  )
}
